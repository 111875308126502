/*
Voor het aanpassen van de Bootstrap classes kan het soms erg zoeken zijn. Op http://bootstrapcustomizer.com/ kan je voorbeelden maken en zoeken
*/

// import the various scss variables files
@use "colours";
@use "fixed-sizes";
// ag-Grid themes:
// https://www.ag-grid.com/javascript-grid-themes-provided/
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
// @import "ag-grid-community/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin";
@import "ag-grid-community/styles/ag-theme-balham.css";
// @import "ag-grid-community/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin";
// @use "node_modules/ag-grid-community/styles" as ag;
// @include ag.grid-styles();
// pagina indeling dingen, flexbox e.d.
@import "./layout-templates.scss";

// fonts:
@font-face {
  font-family: "proxima_nova_rgregular";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.eot");
  src: url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.woff2") format("woff2"),
    url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.woff") format("woff"),
    url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.ttf") format("truetype"),
    url("/assets/fonts/proximanova-regular/proximanova-regular-webfont.svg#proxima_novaregular_italic") format("svg");
}

@import "~@ng-select/ng-select/themes/default.theme.css";

// font-awesome fonts
$fa-font-path: "/assets/fontawesome/webfonts";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/regular.scss";
// @import "./fontawesome/brands.scss";

// .ag-theme-alpine {
//   @include ag.theme-alpine();
// }

// .ag-theme-alpine-dark {
//   @include ag.theme-alpine-dark();
// }

// .ag-theme-balham {
//   @include ag.theme-balham();
// }

// .ag-theme-balham-dark {
//   @include ag.theme-balham-dark();
// }

/* Box-sizing border-box */
* {
  box-sizing: border-box;
}

/* in Edge verschijnt een clear X button om de tekst in een input field te kunnen verwijderen, met onderstaand 
zou die X button verwijderd kunnen worden */

::-ms-clear {
  display: none;
}

// our own classes:
%hover {
  color: colours.$link-hover-colour;
}

%boldtext {
  font-weight: 600;
}

$disabled_opacity: 0.65;

$input_field_height: calc(1.5em + 0.75rem + 2px);

a,
.like_link {
  color: colours.$link-colour;
  cursor: pointer;
}

.like_link_subtle {
  cursor: pointer;
  text-decoration: underline dotted;
}

a:hover,
.like_link:hover,
.like_link_subtle:hover {
  @extend %hover;
  text-decoration: underline; // a heeft dit al, maar like_link niet
}

.strong_text {
  @extend %boldtext;
}

// classes van de "er wordt geladen" pagina's (dus index.html terwijl de Javascript gedownload wordt en app.component terwijl Angular geladen wordt)
.loading_background {
  background-image: url("/customerdata/media/background_image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.loading_header {
  display: flex;
  align-items: center;
}

.loading_text {
  font-size: 1.3em;
}
// einde laden pagina's classes

/* start header component */

.text_larger {
  font-size: 1.2em;
}

// opmaak van knoppen (en dingen die als knop werken zoals .only_icon)
%default_button {
  background-color: colours.$button-background-colour;
  box-shadow: 0 1px 4px colours.$general-shadow-colour-dark;
  color: colours.$button-text-colour;
  cursor: pointer;
  min-width: 80px;
  outline: 0 none;
  padding: 0.5em 1.4em;
  position: relative;
}

%default_button_hover {
  background-color: colours.$button-hover-background-colour;
  border: 1px solid;
  border-color: colours.$button-hover-border-colour;
  box-shadow: none;
  color: colours.$button-hover-text-colour;
  outline: 0 none;
}

// een btn-group is een manier om meerdere opties weer te geven, als het er maximaal drie of vier zijn kan dit de voorkeur hebben boven een lijst
.btn-group {
  &.open .dropdown-toggle {
    box-shadow: none;
  }
  .btn {
    // de knoppen in een groep worden weergegeven alsof je je muis eroverheen houdt
    // dat kan onpractisch blijken, met de huidige kleurstelling ziet het er wel goed uit
    @extend %default_button_hover;
    &.active {
      // de actieve selectie ziet er uit als een standaard knop
      @extend %default_button;
    }
  }
}

// een input-group is een invulveld met daarin een knop
// we gebruiken de hover knop als basis, omdat die bijvoorbeeld geen schaduw heeft
.input-group {
  .btn {
    @extend %default_button_hover;
    color: colours.$general-text-colour;
    background-color: colours.$general-background-colour;
    min-width: 0; // normaal gesproken is een knop minimaal 80px breed
  }
  .input-group-text {
    @extend %boldtext;
  }
  // de eigenschappen van form-control staan verderop met die van .ng-select
  // .form-control {
  //   border-color: colours.$general-border-colour;
  // }
}

.btn {
  @extend %default_button;
  &:disabled {
    opacity: $disabled_opacity;
  }
  &:enabled:hover {
    @extend %default_button_hover;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  // de button_wait class is een knop met twee spans er in, normaal gesproken zie je de eerste, als de knop wordt uitgeschalekd wordt de tweede getoond
  // het moeten twee spans zijn, omdat je anders een van grootte verspringende knop krijgt of een niet gecentreerde tekst doordat de ruimte van het draaiding wordt gereserveerd
  &.button_wait {
    &:disabled {
      // de eerste span is de normale
      span:nth-child(1) {
        opacity: 0;
      }
      // de tweede span is degene die zichtbaar wordt als de knop uitgeschakeld is
      span:nth-child(2) {
        opacity: 1;
      }
    }
    span:nth-child(2) {
      /* dit zorgt ervoor dat de tweede span weergegeven wordt bij een uitgeschakelde knop, dit is de span die aangeeft dat er geladen wordt */
      left: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
  &.button_add_create {
    background-color: colours.$add-create-colour;
  }
  &.button_cancel_delete {
    background-color: colours.$cancel-delete-colour;
  }
  &.button_close {
    background-color: colours.$general-background-colour;
    color: colours.$general-text-colour;
    opacity: 0.7;
  }
}

.only_icon {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  padding: 0.5em 1em;
  color: colours.$general-text-colour;
  &:hover:enabled {
    @extend %hover;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:disabled {
    opacity: $disabled_opacity;
  }
  &.cancel_delete,
  &.save {
    font-size: 1.5rem;
    text-shadow: 0 1px 0 colours.$general-shadow-colour;
    cursor: pointer;
    // padding wat minder, want het icoon is groter (het was 0.5em 1em)
    padding: 0.5em 0.5em;
    &:hover {
      text-shadow: 1px 2px 0 colours.$general-shadow-colour;
    }
    // het kan zijn dat het een slecht idee is om de kleuren op deze manier te doen, want het werkt dan alleen in combinatie met dat specifieke icoon
    &.fa-remove {
      color: colours.$cancel-delete-colour;
    }
    &.fa-save {
      color: colours.$blue-accent;
    }
  }
  &.button_wait {
    &:disabled {
      // de eerste span is de normale
      span:nth-child(1) {
        opacity: 0;
      }
      // de tweede span is degene die zichtbaar wordt als de knop uitgeschakeld is
      span:nth-child(2) {
        opacity: 1;
      }
    }
    span:nth-child(2) {
      /* dit zorgt ervoor dat de tweede span weergegeven wordt bij een uitgeschakelde knop, dit is de span die aangeeft dat er geladen wordt */
      left: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
}

.helptext_icon {
  margin-left: 0.3rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: colours.$button-background-colour;
  border-color: colours.$button-border-colour;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: colours.$button-background-colour;
  border-color: colours.$button-border-colour;
  opacity: $disabled_opacity;
}

.checkbox_container {
  /* De marge maakt ruimte voor het aanvink vakje, die heeft een left: -25px eigenschap
     op deze manier lijnt het geheel dus weer netjes uit met de omringende elementen */
  margin-left: 25px;
}

.checkbox_container input[type="checkbox"] {
  display: none !important;
}

.checkbox_container input[type="checkbox"] + label span {
  cursor: pointer;
  position: relative;
}

.checkbox_container input[type="checkbox"] + label span:before,
input[type="checkbox"] + label span:after {
  border: 1px solid;
  border-radius: 3px;
  height: 20px;
  left: -25px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 0;
  vertical-align: middle;
  width: 20px;
}

.checkbox_container input[type="checkbox"] + label span:before {
  /* content is nodig, omdat je anders geen lijnen ziet bij niet aangevinkt */
  border-color: colours.$checkbox-unchecked-border-colour;
  box-shadow: inset 0 0 8px 0 colours.$general-shadow-colour-light;
  content: "";
}

.checkbox_container input[type="checkbox"] + label span:after {
  /* dit is het vinkje */
  border-color: colours.$checkbox-checked-border-colour;
  color: colours.$general-text-colour;
  content: "\2713";
  font-size: 1.2em;
  text-shadow: 0 1px 0 colours.$general-shadow-colour;
  transition: all 0.3s;
}

.checkbox_container input[type="checkbox"]:not(:checked) + label span:after {
  opacity: 0;
  transform: scale(0);
}

.checkbox_container input[type="checkbox"]:checked + label span:after {
  opacity: 1;
  transform: scale(1);
}

// we hebben geen uitgeschakelde vakjes momenteel, dus ik heb geen idee of dit er uit ziet zoals we willen
// ik heb er verder ook geen tijd aan besteed, misschien gaan we ze wel nooit hebben
.checkbox_container input[type="checkbox"]:disabled + label span:before {
  border-color: colours.$checkbox-unchecked-border-colour;
  box-shadow: none;
  // background-color: #ddd;
  opacity: $disabled_opacity;
}

.checkbox_container input[type="checkbox"]:disabled:checked + label span:after {
  border-color: colours.$checkbox-checked-border-colour;
  color: colours.$general-text-colour;
  opacity: $disabled_opacity;
}

.checkbox_container input[type="checkbox"]:disabled + label span {
  // color: #aaa;
  opacity: $disabled_opacity;
}

.tooltip .tooltip-inner {
  background-color: colours.$general-background-colour;
  color: colours.$general-text-colour;
  width: 250px;
}

/* product component: carousel controls can not be overriden in the product component css unfortunately (jawel, als je ng-deep:: gebruikt, maar we laten het hier staan)*/

.carousel-control {
  opacity: 1;
}

.carousel-indicators {
  bottom: 0;
  top: 80px;
}

.carousel-indicators .active {
  background-color: colours.$medium-grey;
}

.carousel-indicators li {
  border: 1px solid colours.$medium-grey;
}

.filters > li {
  display: inline-block;
  margin-right: 8px;
  margin-top: 2px;
}

// aanpassingen op ngx-bootstrap accordion, ik ben het niet eens met de achtergronden en de lijnen zoals die standaard zijn
.panel-default > .panel-heading {
  background: none;
}

.panel-default {
  border: 0 none;
}

.card-header {
  border: 0 none;
}

.panel {
  border-bottom: 1px solid colours.$general-border-colour-light;
}

/* Bootstrap overrides */

.bg-danger {
  background-color: colours.$general-background-colour;
  border: 1px solid colours.$medium-grey;
  border-radius: 4px;
  color: colours.$danger-alert-colour;
  // font-weight: 400;
  padding: 5px;
}

/* End Bootstrap overrides */

.overflow_dots_nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow_dots_wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary_header {
  @extend %boldtext;
  background-color: colours.$light-grey;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.serious_error {
  color: colours.$danger-alert-colour;
}

.tooltip {
  background-color: colours.$general-background-colour;
}

.tooltip .tooltip-inner {
  @extend %boldtext;
  background-color: rgba(colours.$dark-grey, 0.13);
}

.number_circle {
  border: 1px solid; // geen kleur zodat de tekstkleur gebruikt wordt van de plek waar je de cirkel tekent
  border-radius: 50%;
  display: inline-block;
  height: 1.5em;
  line-height: 1em;
  padding: 0.25em;
  text-align: center;
  width: 1.5em;
  // de negatieve marge zorgt ervoor dat een eventuele knop waar dit in staat niet hoger wordt
  margin-top: -1em;
}

.no_left_right_padding {
  padding-left: 0;
  padding-right: 0;
}

// uiterlijk ng-select en form-control dingen om te zorgen dat ze er redelijk hetzelfde uitzien
.ng-select .ng-select-container {
  border-color: colours.$general-border-colour;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
  border-color: colours.$general-focus-colour;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  // de achtergrond kleur van de geselecteerde dingen
  background-color: colours.$light-grey;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  // de achtergrond kleur van de geselecteerde keuzes in de lijst
  background-color: colours.$light-grey;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  // de :hover kleur van de dingen in de lijst
  background-color: colours.$light-grey;
}
.ng-select.ng-select-single .ng-select-container,
.ng-select .ng-select-container {
  // hoogte zelfde als dat van de form-control, maar het mag wel hoger zijn, dat is handig als je veel dingen selecteert
  min-height: $input_field_height;
}

.form-control {
  height: $input_field_height;
  border-color: colours.$general-border-colour;
}

.form-control:focus {
  box-shadow: none;
  border-color: colours.$general-focus-colour;
}

@media (min-width: fixed-sizes.$min-width-medium) {
  /* content component */
  .quadrant {
    position: relative;
  }

  .filler {
    line-height: 100px;
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .filler_2rows {
    line-height: 40px;
    margin-bottom: 2em;
    margin-top: 2em;
  }
  /* end content component */
}

@media (min-width: fixed-sizes.$min-width-extralarge) {
  /* content component */
  .filler {
    line-height: 100px;
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .filler_2rows {
    line-height: 0px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

/* end content component 
  Safari 9.0 (iOS Only) 
  https://browserstrangeness.bitbucket.io/css_hacks.html#safari 
  Dit lijkt het goed te doen, maar is niet grondig gecontroleerd en wordt momenteel ook niet (meer) gebruikt

@supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
  .ios_safari_only_hide {
    display: none;
  }
}

@media(max-width:767px) {
  @supports (-webkit-text-size-adjust: none) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
    .ios_safari_only_hide_sm {
      display: none;
    }
  }
}
*/

/* Set up a default font and some padding to provide breathing room */
body {
    /* font-size: 15px; */
    color: colours.$general-text-colour;
    font-family: "proxima_nova_rgregular", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 140%;
}

%belowheader {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

%header_template {
    // dit zou beter moeten werken dan osl_header
    // de hoogte van de header wordt uiteindelijk waarschijnlijk bepaald door het logo,
    // deze heeft via .company_logo een maximum hoogte van 66px, bij een heel plat logo wordt de header minder hoog
    background-color: colours.$general-background-colour;
    border-bottom: 1px solid colours.$medium-grey;
    flex: 0 1 auto;
}

.osl_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.osl_header {
    @extend %header_template;
}

.osl_header_padding {
    // de navbar van de b2b voegt padding toe, om een andere header dezelfde afmetingen te geven kan je deze class gebruiken
    @extend %header_template;
    padding: 0.5rem 1rem;
}

.osl_footer {
    background-color: colours.$general-background-colour;
    border-top: 1px solid colours.$medium-grey;
    padding: 10px 40px 10px 0;
}

.below_header {
    @extend %belowheader;
}

.below_header_scroll {
    @extend %belowheader;
    overflow: hidden;
}

.company_logo {
    cursor: pointer;
    display: block;
    height: auto;
    max-height: fixed-sizes.$header-element-height;
    max-width: 100%;
    width: auto;
}
/* end header component */

/* voor een pagina met een grid, zet de header class voor alles dat boven het grid weergegeven wordt de de conten class op het ag-grid element zelf 
   er is nu een aparte scss voor een grid pagina; grid_page.scss, importeer die in de component om te gebruiken in plaats van wat hieronder staat
   het is de bedoeling dit te werwijderen wanneer alles is omgezet */
.grid_box {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.grid_header {
    flex: 0 1 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.grid_content {
    // geef het grid een initiële hoogte, dat maakt de heigt hieronder voor Safari als het meezit overbodig
    flex: 1 1 100px;
    padding: 0 15px 5px 15px;
}

/* einde grid pagina */

.sticky_top_row {
    background-color: colours.$general-background-colour;
    height: auto;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: sticky;
    top: 0;
    /* z-index zo algemeen is misschien niet zo'n goed idee, later moet het dus misschien verplaatst naar de component css */
    /* de carousel op de artikel pagina is 15, het menu is 1000 (maar lijkt in de praktijk 20 te zijn) */
    /* 16 zorgt er dus voor dat de zoekbalk er tussenin zit, op andere plekken is dit getal momenteel ook prima */
    z-index: 16;
}

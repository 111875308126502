// general purpose colours
// de variabele namen zijn niet geweldig en moeten nog beter
$light-grey: rgb(238, 238, 238);
$medium-grey: rgb(170, 170, 170);
$darker-grey: rgb(128, 128, 128);
$dark-grey: rgb(50, 50, 50);
$blue-accent: rgb(0, 75, 126);
$light-blue: rgb(128, 189, 255);

// general background colour for general use
$general-background-colour: rgb(255, 255, 255);

// overall text colour
$general-text-colour: rgb(51, 51, 51);

// colour for clickable links
$link-colour: $blue-accent;

// clickable links and icons hover colour
$link-hover-colour: rgb(217, 54, 0);

// buttons and switches colours
$button-background-colour: $blue-accent;
$button-text-colour: rgb(255, 255, 255);
$button-border-colour: $blue-accent;
$button-hover-background-colour: rgb(255, 255, 255);
$button-hover-text-colour: $blue-accent;
$button-hover-border-colour: $blue-accent;
$cancel-delete-colour: $link-hover-colour;
$add-create-colour: rgb(51, 126, 0);

// checkbox colours
$checkbox-unchecked-border-colour: $medium-grey;
$checkbox-checked-border-colour: rgb(0, 0, 0);

// danger and alert colour
$danger-alert-colour: rgb(255, 0, 0);

// border colour, to use for borders in a menu for instance
$general-border-colour: $medium-grey;
// a lighter border for smaller elements such as a text or quantity entry field
$general-border-colour-light: $light-grey;
$general-border-colour-dark: $darker-grey;

// selection, focus and hover colours
$general-hover-colour: $light-grey;
$general-selected-colour: $medium-grey;
$general-focus-colour: $light-blue;

// (table) headers colours
$header-background-colour: $light-grey;

// colours for (box) shadows
$general-shadow-colour-light: rgba(invert($general-background-colour), 0.1);
$general-shadow-colour: rgba(invert($general-background-colour), 0.4);
$general-shadow-colour-dark: rgba(invert($general-background-colour), 0.6);

// voor een andere keer:
// https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/

// !!
// deze variabelen zijn op een paar plekken in de B2B in gebruik, omdat omzetten naar een nettere oplossing teveel gedoe geeft
// gebruik dit niet tenzij het echt niet anders kan (en gebruik het ook dan niet)

// header height setting
$header-height: 86px;
$footer-height: 51px;
// the space we want between the header and the component below it
// $header-padding: 10px;

// for things like background images that should fall between the header and footer
// 50 pixel extra, want de eipad snap er geen klap van
// $header-footer-height: $header-height + $footer-height + 50px;
// $remaining-height: calc(100vh - #{$header-footer-height}); // want 100vh - $header-height mag niet

// and those elements that should then use all availlable height
// $remaining-height-ex-footer: calc(100vh - #{$header-height}); // want 100vh - $header-height mag niet

// maximum height for elements inside the header
$header-element-height: $header-height - 20px;

// beter zou zijn hier de Bootstrap scss te importeren of iets in de richting, dat moet nog eens worden uitgezocht
// Extra small <576px
$min-width-small: 576px;
$min-width-medium: 768px;
$min-width-large: 992px;
$min-width-extralarge: 1200px;

// Extra small devices (portrait phones, less than 576px)
$max-width-extrasmall: 575.98px;
// Small devices (landscape phones, less than 768px)
$max-width-small: 767.98px;
// Medium devices (tablets, less than 992px)
$max-width-medium: 991.98px;
// Large devices (desktops, less than 1200px)
$max-width-large: 1199.98px;
// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width
